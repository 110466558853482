@import "../../styles/mixins";

.decorated-date {
  line-height: 2em;
  display: inline-block;
  border-bottom: solid 3px #BCE3DF;

  @include for-tablet {
    margin-bottom: 16px;
  }

  &-day {
    font-size: 48px;
  }
  &-month {
    font-size: 36px;
  }
  &-year {
     font-size: 18px;
  }
}