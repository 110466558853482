.Counters {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.ItemCount {
  flex: auto 0;
  padding: 4px;
}