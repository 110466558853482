@import "../../../styles/mixins";

.DiaryItemWrapper.MonthItemWrapper {
  width: 33%;
  @include for-mobile {
    width: 50%;
  }
}

.MonthItemWrapper.Empty {
  color: darkgrey;
}

.MonthsWrapper .DiaryContent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @include for-mobile {
    grid-template-columns: 1fr 1fr;
  }
  @include for-desktop {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
}