@import "../../../styles/mixins";

.EventForm {
  max-width: 558px;
  border-radius: 3px;
  background: #BCE3DF;
  padding: 32px;
  .Input {
    width: 100%;
    box-sizing: border-box;
  }
  input[type="text"], textarea {
    margin: 0;
    border-radius: 2px;
    border-style: inset;
    border-color: rgb(118, 118, 118) rgb(133, 133, 133);
  }
  .form-group {
    margin: 0;
  }
  .buttons-block {
    display: flex;
    padding: 24px 0 0;
    justify-content: space-evenly;
    .button {
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

@include for-tablet() {
  .EventForm {
    max-width: 100%;
    display: flex;
    justify-content: center;
  }
};

.InputElement {
  outline: none;
  border: 1px solid #ccc;
  background-color: white;
  line-height: 40px;
  height: 40px;
  font: inherit;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.TextArea {
  height: 100px;
  line-height: 20px;
  line-height: 20px;
}

.Invalid {
  border: 1px solid red;
  background-color: #FDA49A;
}

.hidden {
  display: none;
}

.react-datepicker-wrapper {
  display: block;
  height: 40px;
}

.Label {
  margin: 12px 0 0;
  font-weight: bold;
  font-size: 0.9em;
}

.react-date-picker.form-control {
  padding: 0;
  .react-date-picker__wrapper {
    width: 100%;
    height: 100%;
  }
}
