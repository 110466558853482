@import "../../styles/mixins";

.DiaryContent {
  display: flex;
  flex-wrap: wrap;
}

.DiaryItemWrapper:hover {
  text-decoration: none;
}

.DiaryItemWrapper.Empty {
  pointer-events: none;
  cursor: default;
}

.DiaryItem {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  &.YearItem {
    justify-content: left;
    align-items: stretch;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    margin: 8px;
    .Counters {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.DiaryItem .Header {
  flex: 0 1 100%;
  min-height: 64px;
  margin-top: 8px;
  text-align: center;
  vertical-align: center;
  font-size: 48px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  @include for-tablet {
    font-size: 32px;
  }
  @include for-mobile {
    font-size: 24px;
  }
}

.DiaryHeader {
  text-align: left;
}

.open-content-btn {
  display: block;
  width: 48px;
  height: 48px;
  background-image: url('../../../public/angle-right.svg');
  background-position: center center;
  background-repeat: no-repeat;
  border: none;
  margin-right: 8px;
}