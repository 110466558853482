.event {
    width: 100%;
    border-radius: 4px;
    height: 68px;
    padding: 16px;
    line-height: 20px;
    margin-top: 16px;
    background-color: #F6F6F6;
    display: flex;
}

.event-title {
    font-size: 18px;
    color: #004085;
    font-style: italic;
}

.single-event {
    &:hover {
        text-decoration: none;
    }
}
