@import "styles/mixins";

.amplify-button {
    background: linear-gradient(163.16deg, #4A4A4A 11.54%, #131313 83.83%);
    border-radius: 3px;
    width: 144px;
    height: 48px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    @include for-tablet() {
        width: 96px;
    }
}