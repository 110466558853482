@import "../../../../styles/mixins";

.DayItem .Header.active {
  color: red;
}

.DayItemHeader {
  text-align: center;
  font-size: 32px;
  font-style: italic;
  color: grey;
  @include for-tablet {
    font-size: 16px;
  }
}

.DaysWrapper .DiaryContent {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  @include for-mobile {
    .Counters {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}