@import "../../styles/mixins";

.day-caption {
    background: #F6F6F6;
    line-height: 56px;
    padding: 0 12px;
    display: flex;
    justify-content: space-between;
    &.current {
        background-color: #BCE3DF;
    }
    &:nth-child(1) {
        margin-left: 0;
    }
    &:nth-child(7) {
        margin-right: 0;
    }
    @include event-day-caption();
    @include for-tablet() {
        padding: 0;
    }
}

@include for-tablet() {
    .day-caption {
        margin: 0 4px;
        justify-content: center;
        line-height: 32px;
    }
    .day-title {
        display: none;
    }
    .separator-part {
        height: 3px;
        line-height: 3px;
        vertical-align: center;
        text-align: center;
    }
}

.day-title {
    font-weight: bold;
}

.day-num {
    display: flex;
    @include for-tablet() {
        flex-direction: column;
    }
}
