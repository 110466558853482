@import '../../styles/mixins';

.AppHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_guest {
    position: absolute;
    padding-left: 160px;
    padding-top: 8px;
  }
}

@include for-tablet() {
  .AppHeader {
    &_guest {
      padding: 0;
      width: 100%;
      justify-content: center;
    }
  }
}