@import '../styles/mixins';

* {
  font-family: var(--amplify-font-family), Raleway, "Open Sans";
}

html, body, .AppWrapper, amplify-authenticator {
  height: 100%;
}

.AppWrapper {
  &_user {
    margin: 10px 10px 0;
  }
}

.App {
  width: 100%;
  padding: 16px;
}

amplify-greetings {
  justify-content: flex-end;
  --border-color: transparent;
}

amplify-authenticator {
  display: flex;
  justify-content: stretch;
}

.SignInFormWrapper, .jumbotronWrapper {
  height: 100%;
  padding-top: 6em;
}

.jumbotronWrapper {
  flex: 1;
  background-image: url('../../public/girl.svg');
  background-repeat: no-repeat;
  background-position: top 420px left 280px;
  .jumbotron {
    h1 {
      font-size: 36px;
      font-weight: bold;
    }
    .lead {
      font-size: 16px;
      width: 325px;
    }
    background: transparent;
    padding: 0 2em;
  }
}

.HomeContent {
  display: flex;
  height: 100%;
}

.SignInFormWrapper {
  background-color: #BCE3DF;
  padding-left: 160px;
  padding-right: 2em;
}

.BottomPicture {
  position: absolute;
  height: 221px;
  width: 588px;
  bottom: 0px;
  left: 492px;
  background-image: url('../../public/picture.svg');
  background-repeat: no-repeat;
  background-position: bottom 0px left 0px;
}

.TopPicture {
  position: absolute;
  height: 114px;
  width: 297px;
  top: 0px;
  right: 33%;
  background-image: url('../../public/picture_top.svg');
  background-repeat: no-repeat;
  background-position: top 0px left 0px;
}

.RightPicture {
  z-index: -1;
  position: absolute;
  height: 354px;
  width: 355px;
  right: 24px;
  top: 92px;
  background-image: url('../../public/picture_right.svg');
  background-repeat: no-repeat;
  background-position: top 0px left 0px;
}

div#root {
  height: 100%;
  @include for-desktop() {
    font-size: 20px;
  }
}

div#root>div {
  height: 100%;
}

.events-header-container {
   margin-bottom: 32px;
  @include for-tablet() {
    margin-bottom: 16px;
  }
}

.events-header {
  font-size: 1.3em;
  font-weight: bold;
  line-height: 2em;
  display: inline-block;
  border-bottom: solid 3px #BCE3DF;
}

.button {
  height: 52px;
  margin-right: 16px;
  line-height: 52px;
  border: solid 1px black;
  border-radius: 3px;
  min-width: 144px;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  &-action {
    background: black;
    color: white;
  }
}

.hide {
  display: none;
}

@include for-tablet() {
  .TopPicture, .BottomPicture, .RightPicture, .jumbotronWrapper {
    display: none;
  }
  .SignInFormWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

img.img-btn {
  &:not(:hover) {
    filter: grayscale(100%);
  }
  &:hover {
    cursor: pointer;
  }
}

.buttons-block {
  display: flex;
  align-items: center;
}