@import "../../../styles/mixins";

.event-block {
    display: flex;
    flex-direction: column;
    margin-top: 18px;
    .details-block, .album-block {
        width: 100%;
    }
    .event-title-block {
        display: flex;
        justify-content: stretch;
        @include for-tablet() {
            flex-direction: column-reverse;
        }
    }
    .event-title {
        font-size: 36px;
        font-weight: bolder;
        flex: 1;
    }
    .event-description {
        font-size: 18px;
        background-color: antiquewhite;
        padding: 20px;
        white-space: pre-wrap;
    }
    @include for-tablet() {
        flex-direction: column;
    }
}
.video {
    display: flex;
    align-items: flex-start;
    img {
        &:not(:hover) {
            filter: grayscale(100%);
        }
        &:hover {
            cursor: pointer;
        }
    }
}
